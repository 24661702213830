.form-loading {
  position: absolute;
  background-color: #0000003a;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 2;
}
.form-loading > div {
  width: 80px;
  height: 80px;
  position: relative;
  border: solid 2px;
  border-color: var(--main-color) transparent var(--main-color)
    var(--main-color);
  border-radius: 50%;
  animation: rotate linear infinite 2s;
}
.form-loading > div > div {
  width: 60px;
  height: 60px;
  position: relative;
  border: solid 2px;
  border-color: transparent var(--main-color) var(--main-color);
  border-radius: 50%;
}
.form-loading > div > div > div {
  width: 40px;
  height: 40px;
  position: relative;
  border: solid 2px;
  border-color: transparent var(--main-color) transparent;
  border-radius: 50%;
}
@keyframes rotate {
  100% {
    rotate: 360deg;
  }
}
