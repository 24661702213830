.login-container form.login {
  flex-direction: column;
  background: var(--body-color);
  width: 460px;
  padding: 20px 30px;
  box-shadow: 0px 0px 11px 0px #c6c6c6;
  border-radius: 10px;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
}
.login-container form.login > h1 {
  text-transform: capitalize;
  color: var(--font-color);
}
body.dark .login-container form.login {
  box-shadow: 0px 0px 20px 0px #000000;
}
.login-container form.login .profile-log {
  width: 100%;
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.login-container form.login .profile-log .icon-background {
  border-radius: 50%;
  background: var(--main-color);
  display: flex;
  justify-content: center;
  width: 155px;
  align-items: center;
  height: 155px;
  box-shadow: 0px 4px 11px 0px #c6c6c6;
}
body.dark .login-container form.login .profile-log .icon-background {
  box-shadow: 0px 4px 20px 0px #1088a387;
}
.login-container form.login .profile-log .icon-background i {
  font-size: 80px;
  color: white;
}
.login-container form.login .input-wrapper {
  width: 100%;
}
.login-container form.login .input {
  width: 100%;
  background: #ff000000;
  border-bottom: 1px solid var(--border-color);
  position: relative;
  margin-bottom: 40px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
}

.login-container form.login .input input {
  width: 100%;
  border: none;
  outline: none;
  background: transparent;
  color: var(--font-color);
  padding: 6px 13px;
}
.login-container form.login .input input::placeholder {
  font-size: 15px;
  letter-spacing: 2px;
  color: var(--font-color);
  opacity: 0.6;
  transition: 0.4s;
}

.login-container form.login .input i {
  font-size: 18px;
  color: var(--main-color);
}

.login-container form.login .input i.password {
  cursor: pointer;
  opacity: 0.8;
}
.login-container form.login button {
  width: 100%;
}
.login-container form.login .input::before {
  content: "";
  position: absolute;
  width: 0%;
  height: 1px;
  background-color: var(--main-color);
  left: 50%;
  bottom: -1px;
  transition: 0.4s;
  opacity: 1;
}
.login-container form.login .input::after {
  content: "";
  position: absolute;
  width: 0%;
  height: 1px;
  background-color: var(--main-color);
  right: 50%;
  bottom: -1px;
  transition: 0.4s;
  opacity: 1;
}

.login-container form.login .input:focus-within::before,
.login-container form.login .input:focus-within::after {
  width: 50%;
}

@media (max-width: 680px) {
  .login-container form.login {
    width: 80%;
  }
  .login-container form.login .profile-log .icon-background {
    width: 120px;
    height: 120px;
  }
  .login-container form.login .profile-log .icon-background i {
    font-size: 50px;
  }
  .login-container form.login .input i {
    font-size: 16px;
  }
}

@media (max-width: 500px) {
  .login-container form.login {
    width: 100%;
  }
  .login-container form.login .profile-log .icon-background {
    width: 110px;
    height: 110px;
  }
  .login-container form.login .profile-log .icon-background i {
    font-size: 45px;
  }
}
