.academy-page .flex-1 * {
  font-weight: 500;
  color: var(--font-color);
}
.academy-page .flex-1 h1.title {
  line-height: 1;
  letter-spacing: 10px;
  overflow: visible;
  font-size: 30px;
}

.academy-page .academy-text {
  transform: translateX(-200%);
  opacity: 0;
  transition: 0.9s;
}
.academy-page .academy-text-after {
  transform: translateX(0%);
  opacity: 1;
}
body.arabic .academy-page .academy-text {
  transform: translateX(200%);
  opacity: 0;
  transition: 0.9s;
}
body.arabic .academy-page .academy-text-after {
  transform: translateX(0%);
  opacity: 1;
}
body.arabic .academy-page .academy-logo {
  transform: translateX(-200%);
  opacity: 0;
  transition: 0.9s;
}
body.arabic .academy-page .academy-logo-after {
  transform: translateX(0%);
  opacity: 1;
}
.academy-page .academy-logo {
  transform: translateX(200%);
  opacity: 0;
  transition: 0.9s;
}
.academy-page .academy-logo-after {
  transform: translateX(0%);
  opacity: 1;
}

.academy-page .mission {
  margin-top: 50px;
  align-items: flex-start;
}
.academy-page .mission > div {
  background-color: var(--section-color);
  padding: 15px 10px;
  border-radius: 6px;
}
.academy-page .mission > div.background {
  background-color: #07c1eb15;
}
.academy-page .mission > div.before-animation {
  opacity: 0;
  transition: 0.9s;
}

.academy-page .mission > div.after-animation {
  opacity: 1 !important;
}
.academy-page .mission h2 {
  padding-left: 20px;
  position: relative;
  letter-spacing: 1px;
  text-transform: capitalize;
  color: var(--font-color);
  font-size: 20px;
  margin-bottom: 5px;
}
body.arabic .academy-page .mission h2 {
  padding-left: 0;
  padding-right: 20px;
}
.academy-page .mission h2::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 8px;
  height: 8px;
  background-color: var(--main-color);
  border-radius: 50%;
}
body.arabic .academy-page .mission h2::before {
  right: 0;
}
.academy-page .mission p {
  padding-left: 25px;
  margin-bottom: 5px;
  color: var(--font-color);
  opacity: 0.9;
  font-weight: 550;
}
body.arabic .academy-page .mission p {
  padding-left: 0;
  padding-right: 25px;
}
.academy-page .mission p.count {
  counter-increment: custom-counter;
  position: relative;
  padding-left: 50px;
}
body.arabic .academy-page .mission p.count {
  padding-right: 50px;
}
.academy-page .mission p.count::before {
  content: counter(custom-counter) ".";
  position: absolute;
  left: 25px;
  font-weight: bold;
}
body.arabic .academy-page .mission p.count::before {
  right: 25px;
}
.academy-contact > h3 {
  font-weight: 600;
  margin-bottom: 30px;
  max-width: 60%;
  color: var(--font-color);
}
.academy-card {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 40px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 0 20px #cbcbcb;
}
body.dark .academy-card {
  box-shadow: 0 0 20px #0000009a;
}
.academy-card:nth-child(odd) {
  flex-direction: row-reverse;
}
.academy-card > div {
  flex: 50%;
  display: flex;
  padding: 20px;
}
.academy-card > div.info {
  justify-content: center;
  flex-direction: column;
  background: var(--section-color);
  padding-left: 30px;
}
.academy-card > div.info h1 {
  font-size: 27px;
  text-transform: capitalize;
  letter-spacing: 1px;
  color: var(--font-color);
}
.academy-card > div.info h3 {
  font-weight: 300;
  font-size: 20px;
  color: var(--font-color);
  opacity: 0.7;
}
.academy-card > div.info a {
  display: flex;
  padding: 10px;
  color: white;
  margin-top: 20px;
  background-color: var(--main-color);
  width: fit-content;
  border-radius: 4px;
  text-transform: capitalize;
  letter-spacing: 1px;
  position: relative;
  overflow: hidden;
}

.academy-card > div.info a::before {
  content: "";
  position: absolute;
  width: 30px;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.384);
  top: 0;
  transform: skewx(-20deg);
  backdrop-filter: blur(1px);
  left: -50%;
  transition: 0.4s;
}
.academy-card > div.info a:hover::before {
  left: 150%;
}
.academy-card > div img {
  aspect-ratio: 4/4;
  max-height: 450px;
  width: 100%;
  object-fit: cover;
  height: 100%;
  border-radius: 4px;
}

.contact-comp .info > div:first-child {
  flex: 1;
}

.contact-comp .info > div:first-child i {
  color: var(--hover-color);
  margin-right: 10px;
}
body.arabic .contact-comp .info > div:first-child i {
  margin-right: 0px;
  margin-left: 10px;
}
.contact-comp .info > div h3 {
  font-weight: 600;
  color: var(--font-color);
  line-height: 1;
  text-transform: capitalize;
}
.contact-comp .info > div h3.title {
  margin-bottom: 20px;
  font-weight: 700;
}
.contact-comp .info > div a {
  color: var(--font-color);
  opacity: 0.9;
}
.contact-comp .info > div:first-child article {
  margin-bottom: 20px;
}

.contact-comp .info > div:first-child > a {
  color: white;
}
.leaflet-container {
  z-index: 1 !important;
  width: 50% !important;
  height: 50vh !important;
}
@media (max-width: 1050px) {
  .academy-page > .container > .main > .flex {
    flex-direction: column-reverse;
  }
  .academy-page .flex-1 h1.title {
    width: 100%;
    text-align: center;
  }
  .academy-card > div.info h1 {
    font-size: 23px;
  }
  .academy-card > div.info h3 {
    font-size: 17px;
  }
}
@media (max-width: 890px) {
  .contact-comp .info > div:first-child {
    margin-bottom: 30px;
  }
  .leaflet-container {
    width: 100% !important;
    height: 50vh !important;
    z-index: 1 !important;
  }
  .contact-comp .info {
    flex-direction: column;
  }
  .academy-page .mission > div:nth-child(odd) {
    background-color: var(--section-color);
  }
  .academy-page .mission > div:nth-child(even) {
    background-color: #07c1eb15;
  }
  .academy-page .flex-1 h1.title {
    font-size: 25px;
  }
  .academy-page .flex-1 h2 {
    font-size: 20px;
  }
  .academy-page .flex-1 h3,
  .academy-page .mission h2 {
    font-size: 17px;
  }
  .academy-page .flex-1 h4,
  .academy-page .mission p {
    font-size: 14px;
  }
  .academy-card {
    flex-direction: column;
  }
  .academy-card > div {
    flex: 100%;
  }
  .academy-contact > h3 {
    max-width: 100%;
    text-align: center;
    font-size: 16px;
  }
  .contact-comp .info > div h3.title {
    font-size: 16px;
  }
  .contact-comp .info > div h3 {
    font-size: 16px;
  }
  .contact-comp .info > div a {
    font-size: 14px;
  }
}
@media (max-width: 675px) {
  .academy-page .flex-1 h1.title {
    font-size: 23px;
  }
  .academy-page .flex-1 h2 {
    font-size: 17px;
  }
  .academy-page .flex-1 h3,
  .academy-page .mission h2 {
    font-size: 15px;
  }
  .academy-page .flex-1 h4,
  .academy-page .mission p {
    font-size: 13px;
  }
  .academy-card {
    margin-top: 20px;
  }
  .academy-card > div.info h1 {
    font-size: 20px;
  }
  .academy-card > div.info h3,
  .academy-card > div.info a {
    font-size: 14px;
  }
  .academy-card > div {
    padding: 15px;
  }
  .academy-contact > h3 {
    font-size: 14px;
  }
  .contact-comp .info > div h3.title {
    font-size: 14px;
  }
  .contact-comp .info > div h3 {
    font-size: 14px;
  }
  .contact-comp .info > div a {
    font-size: 12px;
  }
}
@media (max-width: 450px) {
  .leaflet-container {
    width: 100% !important;
    height: 30vh !important;
    z-index: 1 !important;
  }
  .academy-page .flex-1 h1.title {
    font-size: 21px;
  }
  .academy-page .flex-1 h2 {
    font-size: 15px;
  }
  .academy-page .flex-1 h3,
  .academy-page .mission h2 {
    font-size: 13px;
  }
  .academy-page .flex-1 h4,
  .academy-page .mission p {
    font-size: 12px;
  }
  .academy-page .mission h2 {
    padding-left: 14px;
  }
  .academy-page .mission h2::before {
    width: 6px;
    height: 6px;
  }
  .academy-page .mission p.count {
    padding-left: 40px;
  }
  .academy-card > div.info h1 {
    font-size: 16px;
  }
  .academy-card > div.info h3,
  .academy-card > div.info a {
    font-size: 13px;
  }
  .academy-card > div {
    padding: 10px;
  }
}
