footer {
  padding: 30px 0 15px;
  background: #000;
}
footer .grid-3 {
  margin-bottom: 20px;
}
footer .grid-3 h1 {
  line-height: 1;
  font-size: 25px;
  color: #eee;
  text-transform: capitalize;
  margin-bottom: 10px;
}
footer .grid-3 p {
  color: #777;
}

footer .grid-3 > div > a {
  display: block;
  color: #eee;
  text-transform: capitalize;
  font-weight: 500;
  font-size: 17px;
  letter-spacing: 1px;
}
footer .grid-3 > div .icon a:first-child {
  margin-left: 0px;
}
body.arabic footer .grid-3 > div .icon a:first-child {
  margin-right: 0px;
}
footer .grid-3 > div .icon a {
  margin-left: 10px;
  color: #777;
  background-color: #2c2c2c;
  width: 30px;
  height: 30px;
  font-size: 15px;
  border-radius: 2px;
}
body.arabic footer .grid-3 > div .icon a {
  margin-left: 0px;
  margin-right: 10px;
}
footer .grid-3 > div .icon a.twitter:hover {
  background-color: #1da1f2;
}
footer .grid-3 > div .icon a.facebook:hover {
  background-color: #1877f2;
}
footer .grid-3 > div .icon a.whatsapp:hover {
  background-color: #25d366;
}
footer .grid-3 > div .icon a.linkedin:hover {
  background-color: #2a6c8c;
}
footer .grid-3 > div .icon a:hover i {
  color: white;
}
footer .grid-3 > div:hover > a {
  opacity: 0.4;
}
footer .grid-3 > div > a:hover {
  opacity: 1;
}
footer .grid-3 > div > a.active {
  color: var(--main-color);
}
footer .copyright {
  border-top: solid 1px #2d2d2d;
  padding-top: 10px;
  color: #ddd;
}
footer .setting .language .select {
  top: -125px;
}
footer .setting .language .select p {
  color: white;
}
footer .setting .language .center i {
  color: #777;
}
footer .setting .language .center span {
  color: #ddd;
}
@media (max-width: 700px) {
  footer .grid-3 h1 {
    font-size: 22px;
  }
  footer .grid-3 > div > a {
    font-size: 15px;
  }
  footer .grid-3 > div .icon a {
    width: 25px;
    height: 25px;
    font-size: 13px;
  }
  footer .grid-3 p {
    font-size: 13px;
  }
  footer .copyright {
    font-size: 12px;
  }
  footer .grid-3 {
    gap: 10px;
  }
}
@media (max-width: 500px) {
  footer .grid-3 h1 {
    font-size: 19px;
  }
  footer .grid-3 > div > a {
    font-size: 13px;
  }
}
