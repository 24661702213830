.contact-page {
  gap: 30px;
}
.contact-page .contact-comp {
  width: 40%;
  color: var(--font-color);
}

.contact-page form {
  display: flex;
  flex-direction: column;
}

.contact-page form label {
  cursor: text;
  text-transform: capitalize;
  margin-bottom: 10px;
  color: var(--font-color);
  font-weight: 600;
  width: fit-content;
}
.contact-page form .inp {
  outline: none;
  padding: 8px 10px;
  resize: none;
  background: var(--section-color);
  border: solid 1px var(--border-color);
  color: var(--font-color);
  caret-color: var(--main-color);
  border-radius: 4px;
  position: relative;
  margin-bottom: 10px;
}
.contact-page form .inp::placeholder {
  transition: 0.4s;
}
.contact-page form .inp:focus::placeholder {
  opacity: 0;
}
.contact-page .leaflet-container {
  width: 100% !important;
}
.contact-page form .inp:focus {
  border: solid 1px var(--main-color);
}
.contact-page form div.inp {
  text-transform: capitalize;
  cursor: pointer;
}
.contact-page form div.inp > i {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 13px;
  right: 15px;
  pointer-events: none;
}
body.arabic .contact-page form div.inp > i {
  right: auto;
  left: 15px;
}
.contact-page form div.inp > div {
  position: absolute;
  width: 100%;
  background: var(--section-color);
  left: 0;
  z-index: 2;
  top: calc(100% + 2px);
  overflow: hidden;
  max-height: 0;
}

.contact-page form div.inp > div.active {
  overflow-y: auto;
  max-height: 350px;
}

.contact-page form div.inp > div p {
  padding: 6px 10px;
  border-bottom: solid 1px var(--border-color);
  text-transform: capitalize;
  letter-spacing: 1px;
  font-weight: 600;
  color: var(--font-color);
  cursor: pointer;
}
.contact-page form div.inp > div p:hover {
  background-color: #464141;
  color: white;
}
.contact-page form button {
  width: 50%;
  margin-top: 30px;
  border-radius: 4px;
}

/* join us page  */
.join_page.contact-page {
  gap: 0;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 0 20px #dadada;
}
.contact-page form > h2 {
  text-align: center;
  color: var(--font-color);
}
body.dark .join_page.contact-page {
  box-shadow: 0 0 20px #000000;
}
.join_page.contact-page form {
  padding: 30px;
  background-color: var(--body-color);
}
.join_page.contact-page > div {
  background-image: linear-gradient(180deg, #07c1eb4b, var(--body-color));
}
.join_page.contact-page form label.inp {
  cursor: pointer;
  text-align: center;
  color: #777;
  width: 100%;
}
.join_page.contact-page form p {
  color: var(--font-color);
  opacity: 0.7;
}
.join_page.contact-page form label.inp input {
  display: none;
}
.join_page.contact-page form label.inp i {
  margin-left: 10px;
}

.join_page.contact-page > form > button {
  width: 100%;
}
.contact-page .contact-comp .info > div h3.title {
  display: none;
}
@media (max-width: 900px) {
  .join_page.contact-page {
    flex-direction: column-reverse;
  }
  .join_page.contact-page > div img {
    height: 220px;
  }
}
@media (max-width: 760px) {
  .contact-page {
    flex-direction: column;
  }
  .contact-page form button,
  .contact-page .contact-comp {
    width: 100%;
  }

  .contact-page .contact-comp .info > div:first-child {
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    column-gap: 10px;
    justify-content: space-between;
  }
  .join_page.contact-page > div img {
    height: 200px;
  }
  .contact-page form > h2 {
    font-size: 20px;
  }
}

@media (max-width: 500px) {
  .contact-page form label {
    font-size: 13px;
    margin-bottom: 5px;
  }
  .contact-page form div.inp {
    font-size: 12px;
  }
  .contact-page form .inp {
    font-size: 11px;
    padding: 6px 10px;
  }
  .join_page.contact-page > div img {
    height: 140px;
  }
  .contact-page form > h2 {
    font-size: 18px;
  }
}
