.main-dashboard form.dashboard-form {
  width: 70%;
  margin: 0 auto;
  padding: 40px 30px;
  border-radius: 6px;
  overflow: hidden;
  box-shadow: 0 0 12px #dedede;
}
body.dark .main-dashboard form.dashboard-form {
  box-shadow: 0 0 12px #222;
}
.main-dashboard form.dashboard-form > h2 {
  text-align: center;
  margin-bottom: 20px;
  text-transform: capitalize;
  color: var(--main-color);
  font-weight: 600;
}
.main-dashboard form.dashboard-form .flex {
  column-gap: 20px;
  flex-wrap: wrap;
}
.main-dashboard form.dashboard-form .flex > div {
  flex: 1;
  min-width: 150px;
}
.main-dashboard form.dashboard-form .flex > div.arabic {
  direction: rtl;
}
.main-dashboard form.dashboard-form label {
  width: fit-content;
  cursor: text;
  display: block;
  margin-bottom: 10px;
  text-transform: capitalize;
  color: var(--font-color);
}
.main-dashboard form.dashboard-form label.w-100 {
  width: 100%;
  cursor: auto;
}
.main-dashboard form.dashboard-form label.w-100 p {
  margin: 20px 0 10px;
}
.main-dashboard form.dashboard-form label input {
  display: none;
}
.main-dashboard form.dashboard-form label > div {
  width: 100%;
  cursor: pointer;
  margin-bottom: 20px;
  color: #777 !important;
}
.main-dashboard form.dashboard-form label > div i {
  margin: 0 5px;
}
.main-dashboard form.dashboard-form .inp {
  padding: 7px 10px;
  border: solid 1px var(--border-color);
  resize: none;
  background-color: var(--section-color);
  color: var(--font-color);
  outline: none;
  width: 100%;
  margin-bottom: 20px;
  border-radius: 6px;
  caret-color: var(--main-color);
}
.main-dashboard form.dashboard-form .inp:focus {
  border: solid 1px var(--main-color);
}
.main-dashboard form.dashboard-form .inp::placeholder {
  transition: 0.4s;
}
.main-dashboard form.dashboard-form .inp:focus::placeholder {
  opacity: 0;
}
.main-dashboard form.dashboard-form button {
  width: 80%;
  margin: 0 auto;
  display: block;
}

.main-dashboard form.dashboard-form .no-wrap .inp {
  margin: 0;
  padding-right: 30px;
}
.main-dashboard form.dashboard-form .no-wrap i {
  position: absolute;
  right: 10px;
  color: #777;
}
body.arabic .main-dashboard form.dashboard-form .no-wrap i {
  right: auto;
  left: 10px;
}


body.arabic .main-dashboard form.dashboard-form .no-wrap .inp {
  padding-left: 30px;
  padding-right: 10px;
}

.main-dashboard form.dashboard-form > div.remove img {
  margin-bottom: 20px;
}
.main-dashboard form.dashboard-form > div.remove i {
  color: white;
  background-color: var(--main-color);
  position: absolute;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -10px;
  right: -10px;
  cursor: pointer;
}
.main-dashboard form.dashboard-form > div.remove i:hover {
  background: var(--hover-color);
}

.main-dashboard form.dashboard-form.user .role {
  cursor: pointer;
  text-transform: capitalize;
}
.main-dashboard form.dashboard-form.user .role .role-select {
  position: absolute;
  width: 100%;
  background-color: var(--section-color);
  left: 0;
  bottom: calc(-100% - 40px);
  z-index: -1;
  opacity: 0;
}
.main-dashboard form.dashboard-form.user .role .role-select.active {
  opacity: 1;
  z-index: 1;
}
.main-dashboard form.dashboard-form.user .role .role-select p {
  color: var(--font-color);
  border-bottom: solid 1px var(--border-color);
  padding: 5px 20px;
  background-color: var(--section-color);
  text-transform: capitalize;
}
.main-dashboard form.dashboard-form.user .role .role-select p:hover {
  background: var(--border-color);
}
.main-dashboard form.dashboard-form.user .role i {
  color: #777;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}
body.arabic .main-dashboard form.dashboard-form.user .role i {
  right: auto;
  left: 10px;
}

.main-dashboard form.user div.password i {
  color: var(--main-color);
  cursor: pointer;
  position: absolute;
}
.main-dashboard form.user div.password {
  margin-bottom: 20px;
}

@media (max-width: 1000px) {
  .main-dashboard form.dashboard-form {
    width: 100%;
  }
  .main-dashboard form.dashboard-form label {
    font-size: 14px;
  }
  .main-dashboard form.dashboard-form .inp {
    padding: 5px 10px;
  }
  .main-dashboard form.dashboard-form .flex > div {
    min-width: 210px;
  }
  .main-dashboard form.dashboard-form > h2 {
    font-size: 20px;
  }
}

@media (max-width: 600px) {
  .main-dashboard form.dashboard-form > h2 {
    font-size: 17px;
  }
  .main-dashboard form.dashboard-form label {
    font-size: 12px;
    margin-bottom: 7px;
  }
  .main-dashboard form.dashboard-form .inp {
    margin-bottom: 10px;
    padding: 3px 10px;
    font-size: 13px;
  }
  .main-dashboard form.dashboard-form button {
    width: 100%;
    margin-top: 20px;
  }
  .main-dashboard form.dashboard-form {
    padding: 20px 10px;
  }
}
