header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 3;
}
body.arabic header {
  direction: ltr;
}
body.arabic header > .container > .links > i {
  order: 2;
}
body.arabic header > .container > .links > a:first-of-type {
  order: 1;
}
header.pages-desgin {
  background: #0000004d;
  backdrop-filter: blur(5px);
}
body.dark header.pages-desgin {
  background-color: #716c6c21;
}
header.pages-desgin > .container {
  border-bottom: none;
}
header.active {
  background: #00000059;
  backdrop-filter: blur(2px);
}
header > .container {
  padding: 10px 0 5px;
  border-bottom: solid 1px #ffffff9c;
}
header .logo img {
  width: 45px;
  object-fit: contain;
}
header .logo h4,
header .logo h3 {
  line-height: 1;
  color: white;
  font-weight: 600;
  font-size: 14px;
}
header .logo h3 {
  font-size: 16px;
}
header > .container > .links {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
}
.links.flex-1::before {
  content: "";
  width: 1px;
  background: #ffffff9c;
  height: 70%;
  left: 20px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
header > .container > .links > a {
  margin: 0 10px;
  color: white;
  font-weight: bold;
  position: relative;
  text-transform: capitalize;
}
header > .container > .links > i {
  cursor: pointer;
  color: white;
  margin-left: 10px;
}
header > .container > .links > a:hover,
header > .container > .links > a.active {
  color: var(--main-color);
}
header > .container > .links > a::before {
  content: "";
  position: absolute;
  bottom: -13px;
  height: 2px;
  width: 0%;
  background-color: var(--main-color);
  transition: 0.4s;
}
header > .container > .links > a:hover::before,
header > .container > .links > a.active::before {
  width: 100%;
}
.aside-overlay {
  height: calc(100vh - 60px);
  top: 60px;
  position: fixed;
  z-index: -1;
  background: linear-gradient(354deg, #000000de, transparent);
  width: 100%;
  display: flex;
  justify-content: flex-end;
  opacity: 0;
  backdrop-filter: blur(2px);
}
.aside-overlay.open {
  opacity: 1;
  z-index: 1000;
}
.aside-overlay aside.header-aside {
  right: -50%;
  width: 250px;
  position: absolute;
  height: 100%;
  background: linear-gradient(132deg, #181e2c7d, transparent);
  transition: 0.6s;
  box-shadow: -11px 2px 15px 0px #0000003b;
  z-index: 4;
  max-height: 100%;
  overflow-y: auto;
  padding-bottom: 40px;
}
.aside-overlay.open aside.header-aside {
  right: 0;
}
aside.header-aside img {
  width: 40px;
}
aside.header-aside > div.flex:first-of-type {
  align-items: center;
  padding: 10px 15px;
  margin-bottom: 20px;
  justify-content: space-between;
}
body.arabic aside.header-aside > div.flex:first-of-type {
  flex-direction: row-reverse;
}
aside.header-aside > div.flex:first-of-type i {
  cursor: pointer;
  font-size: 21px;
  color: white;
}
aside.header-aside > div.flex:first-of-type i:hover {
  color: #c90606;
}
aside.header-aside > article a {
  display: block;
  color: white;
  padding: 10px 15px;
  border-top: solid 1px #3c3c4175;
  text-transform: capitalize;
  font-weight: bold;
  letter-spacing: 1px;
}

aside.header-aside > article a:hover,
aside.header-aside > article a.active {
  background-color: #1a1d2470;
  color: var(--main-color);
}

aside.header-aside > article a i {
  margin-right: 10px;
}
body.arabic aside.header-aside > article a i {
  margin-right: 0;
  margin-left: 10px;
}
aside.header-aside > .aside-setting {
  padding: 10px 15px;
  width: 100%;
  border-top: solid 1px #3c3c4175;
}

i.scroll-to-top {
  position: fixed;
  background-color: var(--main-color);
  color: white;
  z-index: 2;
  bottom: 40px;
  width: 30px;
  height: 30px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  right: -10%;
  overflow: hidden;
}
i.scroll-to-top.visible {
  right: 40px;
}
body.arabic i.scroll-to-top {
  right: auto;
  left: -10%;
}
body.arabic i.scroll-to-top.visible {
  left: 40px;
  right: auto;
}
i.scroll-to-top::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background-color: var(--hover-color);
  z-index: -1;
  transition: 0.4s;
}
i.scroll-to-top:hover::after {
  width: 100%;
}
@media (max-width: 1000px) {
  header .logo img {
    width: 40px;
  }
  header > .container > .links > a {
    font-size: 14px;
  }
  header .logo h3 {
    font-size: 14px;
  }
  header .logo h4 {
    font-size: 12px;
  }
  aside.header-aside > article a {
    font-size: 15px;
  }
  .aside-overlay {
    height: calc(100vh - 55px);
    top: 55px;
  }
  header > .container > .links > a::before {
    bottom: -12px;
  }
  .setting p {
    font-size: 12px;
  }
}
@media (max-width: 850px) {
  header > .container > .links > a {
    display: none;
  }
}
@media (max-width: 600px) {
  .aside-overlay aside.header-aside {
    width: 220px;
  }
  header .logo h4 {
    font-size: 10px;
  }
  header .logo h3 {
    font-size: 12px;
  }
  aside.header-aside > article a {
    font-size: 13px;
  }
  aside.header-aside img {
    width: 35px;
  }
  aside.header-aside > div.flex:first-of-type i {
    font-size: 18px;
  }
}
@media (max-width: 500px) {
  i.scroll-to-top {
    font-size: 12px;
    width: 25px;
    height: 25px;
  }
  i.scroll-to-top.visible {
    right: 20px;
  }
  body.arabic i.scroll-to-top.visible {
    left: 20px;
  }
}
