.services {
  margin-top: 30px;
  align-items: baseline;
}
.services-page .grid-2 {
  row-gap: 0px;
}
.services > article {
  background: var(--body-color);
  border-radius: 6px;
  text-align: center;
  box-shadow: 0 0 20px #bfbfbf;
  overflow: hidden;
  position: relative;
  transform: translateY(100%);
  opacity: 0;
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}
.services > article::before {
  content: "";
  position: absolute;
  width: 0%;
  height: 2px;
  background-color: var(--main-color);
  transition: 0.4s;
  left: 50%;
}
.services > article::after {
  content: "";
  position: absolute;
  width: 0%;
  height: 2px;
  background-color: var(--main-color);
  top: 0;
  transition: 0.4s;
  right: 50%;
}
.services > article:hover::before,
.services > article:hover::after {
  width: 50%;
}
body.dark .services > article {
  box-shadow: 0 0 20px #19191d;
}
.services > article i {
  font-size: 30px;
  color: var(--hover-color);
  margin-top: 10px;
}
.services > article > div {
  padding: 20px 15px;
}
.services > article > div > div {
  padding: 0 10px;
}
.services > article h1 {
  color: var(--font-color);
  text-transform: capitalize;
}
.services > article p {
  color: var(--font-color);
  max-width: 90%;
  margin: 0 auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.services-page .services > article p {
  white-space: wrap;
}
.services > article > article {
  background-color: #f3f3f3;
}
body.dark .services > article > article {
  background-color: #272727;
}
.services > article > article a.started {
  padding: 8px;
  background: var(--main-color);
  color: white;
  width: 120px;
  position: relative;
  text-transform: capitalize;
  text-align: left;
}
body.arabic .services > article > article a.started {
  text-align: center;
}
.services > article > article a.started:hover {
  background-color: var(--hover-color);
}
.services > article > article a.started::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 25px;
  background-color: #dedede;
  top: 0;
  font-weight: 400;
  right: -12px;
  transform: skewX(-24deg);
}
body.arabic .services > article > article a.started::before {
  left: -12px;
  right: auto;
}
body.dark .services > article > article a.started::before {
  background-color: #343434;
}
.services > article > article a:not(:first-of-type) {
  margin-right: 15px;
  color: var(--font-color);
}
body.arabic .services > article > article a:not(:first-of-type) {
  margin-right: 0;
  margin-left: 15px;
}
.services > article > article a:not(:first-of-type):hover {
  color: var(--main-color);
}
.services-page h3 {
  font-weight: 600;
  letter-spacing: 1px;
  color: var(--font-color);
  margin: 20px 0 50px;
  position: relative;
}
.sevice-info {
  margin: 0;
  align-items: center;
  margin-bottom: 40px;
}

.sevice-info img {
  width: 100%;
  height: 340px;
  object-fit: contain;
}
.services-page .services > article > article a {
  padding: 8px;
  background: #4c4c4c;
  color: white;
  width: 100%;
  position: relative;
  text-transform: capitalize;
  text-align: center;
}
.services-page .services > article > article a:hover {
  background-color: var(--hover-color);
}
.services-animation {
  transform: translateY(0px) !important;
  opacity: 1 !important;
}
.services > article:hover {
  transform: translateY(-10px) !important;
}

@media (max-width: 950px) {
  .sevice-info {
    flex-direction: column-reverse;
  }
  .services > article i {
    font-size: 27px;
  }
  .services > article h1 {
    font-size: 20px;
  }
  .services > article > article a.started {
    width: 110px;
    font-size: 12px;
  }
  .services-page h3 {
    font-size: 16px;
  }
  .sevice-info img {
    height: 240px;
  }
}

@media (max-width: 500px) {
  .services > article h1 {
    font-size: 17px;
  }
  .services > article p {
    font-size: 13px;
  }
  .services > article i {
    font-size: 25px;
  }
  .services-page h3 {
    font-size: 14px;
  }
  .sevice-info img {
    height: 200px;
  }
}
