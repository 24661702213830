.setting {
  align-items: flex-start;
}
.setting p {
  font-size: 13px;
  margin-bottom: 5px;
  color: white;
  text-transform: capitalize;
}
.setting .language .center {
  justify-content: flex-start;
  cursor: pointer;
  width: fit-content;
}
.setting .language .center i {
  font-size: 12px;
  pointer-events: none;
  color: white;
}
.setting .language .center span {
  margin: 0 5px;
  pointer-events: none;
  text-transform: capitalize;
  font-size: 15px;
  color: white;
}
.setting .language .select {
  position: absolute;
  top: -100px;
  width: 130px;
  background-color: #22252c;
  left: 0;
  z-index: -1;
  opacity: 0;
  pointer-events: none;
}
body.arabic .setting .language .select {
  right: 0;
}
.setting .language .select.active {
  opacity: 1;
  z-index: 1;
  pointer-events: all;
}
.setting .language .select p {
  padding: 8px 10px;
  cursor: pointer;
  font-weight: bold;
  margin: 0;
}
.setting .language .select p:not(:first-of-type) {
  border-top: solid 1px #424347;
}
.setting .language .select p:hover {
  background-color: var(--border-color);
}
.setting .mode div {
  width: 40px;
  height: 18px;
  border: 2px solid #505051;
  background-color: #312d2d6b;
  border-radius: 10px;
  cursor: pointer;
  position: relative;
}
.setting .mode div.dark {
  background-color: #1a1d24ad;
}
.setting .mode div::before {
  content: "\f185";
  position: absolute;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  transform: translateY(-50%);
  top: 50%;
  background-color: #858586;
  left: 2px;
  transition: 0.4s;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 8px;
  font-weight: 400;
  font-family: "Font Awesome 6 Free";
  color: white;
}
.setting .mode div.dark::before {
  left: 56%;
  content: "\f186";
}
aside.header-aside > .aside-setting .setting > div {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
@media (max-width: 1000px) {
  .setting .language .center i {
    font-size: 11px;
  }
  .setting .language .center span {
    font-size: 14px;
  }
}
@media (max-width: 600px) {
  .setting p {
    font-size: 12px;
  }
  .setting .language .center i {
    font-size: 10px;
  }
  .setting .language .center span {
    font-size: 13px;
  }
  .setting .mode div {
    height: 15px;
  }
  .setting .mode div::before {
    width: 10px;
    height: 10px;
  }
}
