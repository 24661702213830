.responsive-cell-block {
  min-height: 75px;
}

.responsive-container-block {
  flex-direction: row-reverse;
  margin: 0px auto;
  min-height: 75px;
  height: fit-content;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}
.about-info {
  align-items: center;
}
.about-info img {
  height: 300px;
}
.spikes {
  position: absolute;
  top: 0;
  width: 100%;
  transition: 0.4s;
}
.spikes::after {
  content: "";
  position: absolute;
  right: 0;
  width: 100%;
  height: 30px;
  z-index: 1;
  background-image: linear-gradient(
      135deg,
      var(--section-color) 25%,
      transparent 25%
    ),
    linear-gradient(225deg, var(--section-color) 25%, transparent 25%);
  background-size: 30px 30px;
  transition: 0.4s;
}
.about-info .info-first {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}
.responsive-container-block.bigContainer div.title {
  margin-bottom: 0;
}
.responsive-container-block.bigContainer {
  margin-left: auto;
  margin-right: auto;
}

.responsive-container-block.Container {
  align-items: center;
  padding-top: 10px;
  padding-right: 10px;
  padding-bottom: 0px;
  padding-left: 10px;
  position: relative;
  overflow: hidden;
}

.mainImg {
  width: 100%;
  object-fit: cover;
}
.about-flex {
  margin: 0 auto;
  align-items: start;
}
.about-wrapper {
  width: 100%;
  position: relative;
}
.responsive-container-block .title h1 {
  background: var(--section-color);
}
.blueDots {
  position: absolute;
  top: 150px;
  right: 15%;
  z-index: -1;
  left: auto;
  width: 80%;
  height: 500px;
  object-fit: cover;
}

.imgContainer {
  position: relative;
  width: 48%;
}

.text-blk.heading {
  font-size: 36px;
  line-height: 40px;
  font-weight: 700;
  margin-bottom: 20px;
  color: var(--font-color);
}

.text-blk.subHeading {
  font-size: 18px;
  line-height: 26px;
  margin-bottom: 20px;
  color: var(--font-color);
  opacity: 0.9;
  font-weight: 600;
}
.about-wrapper .btns {
  margin-top: 20px;
  display: flex;
  gap: 30px;
}
.cardImg {
  width: 31px;
  height: 31px;
}

.responsive-cell-block.wk-desk-6.wk-ipadp-6.wk-tab-12.wk-mobile-12 {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 10px;
  padding-right: 15px;
  padding-bottom: 10px;
  padding-left: 15px;
  border-radius: 10px;
  flex-direction: column;
}
.about-flex .background1 {
  background-color: #07c1eb15;
}
.about-flex .background2 {
  background-color: var(--section-color);
}
.text-blk.cardHeading {
  padding-left: 20px;
  position: relative;

  letter-spacing: 1px;
  text-transform: capitalize;
  color: var(--font-color);
  font-size: 20px;
}
body.arabic .text-blk.cardHeading {
  padding-left: 0px;
  padding-right: 20px;
}
.text-blk.cardHeading::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 10px;
  height: 10px;
  background-color: var(--main-color);
  border-radius: 50%;
}
body.arabic .text-blk.cardHeading::before {
  right: 0;
}
.text-blk.cardSubHeading {
  padding-left: 25px;
  margin-bottom: 10px;
  color: var(--font-color);
  opacity: 0.9;
  font-weight: 550;
}
body.arabic .text-blk.cardSubHeading {
  padding-left: 0px;
  padding-right: 25px;
}
#ixvck {
  margin-top: 60px;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
}

@media (max-width: 1024px) {
  .responsive-container-block.Container {
    position: relative;
    align-items: flex-start;
    justify-content: center;
  }
  .about-info {
    flex-direction: column;
  }

  .mainImg {
    bottom: 0px;
  }

  .imgContainer {
    position: absolute;
    bottom: 0px;
    left: 0px;
    height: auto;
    width: 60%;
  }

  .responsive-container-block.textSide {
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: auto;
    width: 70%;
  }

  .responsive-container-block.Container {
    flex-direction: column-reverse;
  }

  .imgContainer {
    position: relative;
    width: auto;
    margin-top: 0px;
    margin-right: auto;
    margin-bottom: 0px;
    margin-left: auto;
  }

  .responsive-container-block.textSide {
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 20px;
    margin-left: 0px;
    width: 100%;
  }

  .responsive-container-block.Container {
    flex-direction: row-reverse;
  }

  .responsive-container-block.Container {
    flex-direction: column-reverse;
  }
}

@media (max-width: 768px) {
  .responsive-container-block.textSide {
    width: 100%;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

  .text-blk.subHeading {
    text-align: center;
    font-size: 17px;
    max-width: 520px;
  }

  .text-blk.heading {
    text-align: center;
  }

  .imgContainer {
    opacity: 0.8;
  }

  .imgContainer {
    height: 500px;
  }

  .imgContainer {
    width: 30px;
  }

  .responsive-container-block.Container {
    flex-direction: column-reverse;
  }

  .responsive-container-block.Container {
    flex-wrap: nowrap;
  }

  .responsive-container-block.textSide {
    width: 100%;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 20px;
    margin-left: 0px;
  }

  .imgContainer {
    width: 90%;
  }

  .imgContainer {
    height: 450px;
    margin-top: 5px;
    margin-right: 33.9062px;
    margin-bottom: 0px;
    margin-left: 33.9062px;
  }

  .redDots {
    display: none;
  }

  .explore {
    font-size: 16px;
    line-height: 14px;
  }
}

@media (max-width: 500px) {
  .imgContainer {
    position: static;
    height: 450px;
  }

  .mainImg {
    height: 100%;
  }

  .blueDots {
    width: 100%;
    left: 0px;
    top: 0px;
    bottom: auto;
    right: auto;
  }

  .imgContainer {
    width: 100%;
  }

  .responsive-container-block.textSide {
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
  }

  .responsive-container-block.Container {
    padding-top: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
    overflow-x: visible;
    overflow-y: visible;
  }

  .responsive-container-block.bigContainer {
    padding-top: 10px;
    padding-right: 20px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding: 0 30px 0 30px;
    width: 100%;
  }

  .redDots {
    display: none;
  }

  .text-blk.subHeading {
    font-size: 16px;
    line-height: 23px;
  }

  .text-blk.heading {
    font-size: 28px;
    line-height: 28px;
  }

  .responsive-container-block.textSide {
    margin-top: 40px;
    margin-right: 0px;
    margin-bottom: 50px;
    margin-left: 0px;
  }

  .imgContainer {
    margin-top: 5px;
    margin-right: auto;
    margin-bottom: 0px;
    margin-left: auto;
    width: 100%;
    position: relative;
  }

  .explore {
    padding-top: 17px;
    padding-right: 0px;
    padding-bottom: 17px;
    padding-left: 0px;
    width: 100%;
  }

  #ixvck {
    width: 90%;
    margin-top: 40px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    font-size: 15px;
  }

  .blueDots {
    bottom: 0px;
    width: 100%;
    height: 80%;
    top: 10%;
  }

  .text-blk.cardHeading {
    font-size: 16px;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 8px;
    margin-left: 0px;
    line-height: 25px;
  }

  .responsive-cell-block.wk-desk-6.wk-ipadp-6.wk-tab-12.wk-mobile-12 {
    padding: 10px;
  }
}

@media (max-width: 898px) {
  .text-blk.heading {
    font-size: 24px;
    margin-bottom: 5px;
  }
  .text-blk.subHeading {
    font-size: 14px;
  }
  .about-info img {
    height: 240px;
  }
  .text-blk.cardHeading {
    font-size: 16px;
    padding-left: 15px;
  }
  .text-blk.cardSubHeading {
    font-size: 13px;
  }
  .responsive-cell-block.wk-desk-6.wk-ipadp-6.wk-tab-12.wk-mobile-12:nth-child(
      odd
    ) {
    background-color: #07c1eb15;
  }
  .responsive-cell-block.wk-desk-6.wk-ipadp-6.wk-tab-12.wk-mobile-12:nth-child(
      even
    ) {
    background-color: var(--section-color);
  }
  .text-blk.cardHeading::before {
    width: 8px;
    height: 8px;
  }
}

@media (max-width: 600px) {
  .text-blk.heading {
    font-size: 21px;
  }
  .text-blk.subHeading {
    font-size: 12px;
    margin-bottom: 5px;
  }
  .about-info img {
    height: 190px;
  }
  .text-blk.cardSubHeading {
    padding-left: 13px;
  }
}
