.projects-page .grid-2 {
  margin: 0;
}
.projects-page .grid-2 h3 {
  font-weight: 500;
  color: var(--font-color);
}
.projects-page .grid-2 h3 a {
  font-weight: 200;
  color: var(--main-color);
}
.projects-page .grid-2 img {
  max-width: 400px;
}

.projects-page .pagination {
  justify-content: center;
  gap: 10px;
  display: flex;
  margin-top: 40px;
}
.projects-page .pagination span {
  background: var(--section-color);
  width: 30px;
  height: 30px;
  text-align: center;
  font-weight: bold;
  color: var(--font-color);
  cursor: pointer;
  border-radius: 4px;
  opacity: 0.8;
  user-select: none;
}

.projects-page .pagination span.active,
.projects-page .pagination span:hover {
  background-color: var(--hover-color);
  opacity: 1;
  color: white;
}

@media (max-width: 700px) {
  .projects-page .grid-2 h3 {
    font-size: 15px;
  }
  .projects-page .grid-2 img {
    display: none;
  }
}

@media (max-width: 500px) {
  .projects-page .grid-2 h3 {
    font-size: 13px;
  }
  .projects-page .pagination span {
    width: 25px;
    height: 25px;
    font-size: 11px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
